<template>
    <Layout>
      <div class="row">
        <div class="col-lg-12">
          <div class="card border border-radius-12">
            <PageHeader :title="title" class="poppins fw-normal ps-3 pt-3" />
            <div class="card-body table">
              <div class="main_header_ctas d-flex gap-2 flex-wrap align-items-center justify-content-between py-2">
                <div :class="{
      'del_btn-wrapper': true,
      'border-orange rounded-pill p1': multipuleIds.length >= 1
    }">
  
    <button
      v-if="multipuleIds.length >= 1"
      @click="deleteBulk"
      class="
        btn
        border-0
        px-5
        fw-bold
        text-white
        btn btn-primary
        waves-effect waves-light
        w-md
        primary-button
        rounded-pill
      "
    >
      Delete All
    </button>
    </div>
              <div class="add_new border-orange rounded-pill p1">  <router-link to="/category/add"
             class="
               btn
               btn-primary
               border-0
               px-5
               fw-bold
               text-white
               btn btn-primary
               waves-effect waves-light
               w-md
               primary-button
               rounded-pill
             "
           >
             Add New
              </router-link></div>
           </div>
              <div class="table-responsive">
                <table
                  id="datatable"
                  class="table table-centered table-nowrap mb-0"
                >
                  <thead class="table-light bg-transparent">
                    <tr>
                      <th style="width: 20px">
                        <div class="form-check font-size-16">
                          <input
                            @change="selectAllRecord"
                            v-model="selectAll"
                            type="checkbox"
                            class="form-check-input"
                            id="customCheck1"
                          />
                          <label class="form-check-label" for="customCheck1"
                            >&nbsp;</label
                          >
                        </div>
                      </th>
                      <th>Id</th>
                      <th>Email</th>
                   
                    </tr>
                  </thead>
                  <tbody>
                    <tr colspan="3" v-if="loading">
                      <td colspan="4">
                        <APILoader
                          :loading="loading"
                          class="align-middle text-center"
                        ></APILoader>
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(category, index) in categories"
                      :key="index"
                    >
                      <th style="width: 20px">
                        <div class="form-check font-size-16">
                          <input
                            v-model="multipuleIds"
                            :value="category.id"
                            type="checkbox"
                            class="form-check-input"
                            id="customCheck1"
                          />
                          <label class="form-check-label" for="customCheck1"
                            >&nbsp;</label
                          >
                        </div>
                      </th>
                      <td>
                        <span>{{ category.title }}</span>
                      </td>
                      <td>{{ category.slug }}</td>
  
                      <!-- <td class="table-icon">
                        <div class="d-flex">
                          <button
                            style="margin: 10px"
                            @click="updateCategory(category.id)"
                            class="p-0 bg-transparent border-0"
                          >
                            <span class="text-primary">
                              <i class="uil-edit"></i>
                            </span>
                          </button>
                          <button
                            @click="deleteCategory(category.id)"
                            class="p-0 bg-transparent border-0"
                          >
                            <span class="text-danger">
                              <i class="uil-trash-alt"></i>
                            </span>
                          </button>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  <script>
  import Layout from "../../../layouts/static/main";
  import PageHeader from "@/components/static/page-header";
  import appConfig from "@/app.config";
  import Swal from "sweetalert2";
  import $ from "jquery";
  export default {
    page: {
      meta: [
        {
          name: "add-user",
          content: appConfig.description,
        },
      ],
    },
    name: "getnotify",
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        multipuleIds: [],
        selectAll: false,
        title: "Get Notify Mail",
        loading: true,
        categories: null,
      };
    },
    mounted() {
      this.allCategory();
    },
    methods: {
      randerData(response) {
        this.categories = response.data.data;
        this.loading = false;
        if ($.fn.dataTable.isDataTable("#datatable")) {
          let table = $("#datatable").DataTable();
          table.destroy();
        }
        setTimeout(() => {
    $("#datatable").DataTable({
      ordering: false,
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      pageLength: 25,
      // columnDefs: [{
      //   targets: '_all',
      //   createdCell: function (td, cellData, rowData, row, col) {
      //     $(td).css('padding', '0px 10px'); 
      //   }
      // }]
    });
  });
      },
  
    //allCategory() {
    //     this.$axios
    //       .get("categories")
    //       .then((response) => {
    //         setInterval(this.randerData(response), 1500);
    //       })
    //       .catch((error) => {
    //         console.log(error.response);
    //       });
    //   },
    //   updateCategory: function (id) {
    //     this.$router.push({
    //       name: "update-category",
    //       params: { id: id },
    //     });
    //   },   
      async delete(id) {
        try {
          const {
            data: { status },
          } = await this.$axios.delete("categories/" + id);
          this.responseDelete = status;
        } catch (error) {
          console.log(error);
        }
      },
      deleteCategory(id) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.delete(id);
            let status = this.responseDelete;
            if (status == true) {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              this.allCategory();
            }
          }
        });
      },
      selectAllRecord() {
        if (this.selectAll) {
          let ids = [];
          this.categories.forEach((element) => {
            ids.push(element.id);
          });
          this.multipuleIds = [...new Set(ids)];
          console.log(this.multipuleIds);
        } else {
          this.multipuleIds = [];
  
          console.log(this.multipuleIds);
        }
      },
      deleteBulk() {
        this.$axios
          .post("delete-category-bulk", { ids: this.multipuleIds })
          .then((response) => {
            this.allCategory();
            this.triggerSwal(response.data.message, "success");
            this.selectAll = false;
            this.multipuleIds = [];
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
    },
  };
  </script>
  